<template>
  <div class="webapp-layout" data-testid="webapp-layout">
    <v-card :data-testid="`step-${activeStepName}`" class="webapp-layout-child">
      <v-app-bar
        v-show="props.showHeader"
        id="step-header"
        color="app-bars"
        :elevation="3"
        class="unset-height-app-bar"
      >
        <v-col class="pa-0">
          <slot name="alert" />
          <slot name="header" />
        </v-col>
      </v-app-bar>

      <div id="step-container">
        <v-row justify="center" align="center" class="ma-0 h-100">
          <v-col cols="12" align="center" justify="center" :class="innerClasses">
            <slot name="body" />
          </v-col>
        </v-row>
      </div>

      <div v-if="$slots.chat" class="webapp-chat">
        <div id="chat">
          <slot name="chat" />
        </div>
      </div>
      <v-footer
        v-show="props.showFooter"
        id="step-footer"
        ref="footer"
        :elevation="3"
        color="app-bars"
      >
        <slot name="footer" />
      </v-footer>
    </v-card>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useFlowStore } from '#src/stores/flow';
import { useDisplay } from 'vuetify';
import { computed, inject } from 'vue';
import { APPLY_ROUTE_META_NAMES } from '#src/enumerations/step-enumeration';

const props = defineProps({ showFooter: Boolean, showHeader: Boolean });

const pinia = inject('pinia');
const flow = useFlowStore(pinia);
const { activeStep } = storeToRefs(flow);

const { mdAndDown } = useDisplay();

const activeStepName = computed(() => APPLY_ROUTE_META_NAMES[activeStep.value?.step]);
const innerClasses = computed(() => {
  if (activeStep.value?.isFullscreen) return 'h-100 pa-0 no-line-height w-100';
  if (mdAndDown.value) return 'px-0 py-1';
  return '';
});
</script>
<style lang="scss">
.v-main {
  padding-top: 0 !important;
}

.webapp-layout {
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  top: 0;
  left: 0;
  position: absolute;

  .webapp-layout-child {
    flex: 1 1 100%;
    max-height: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;

    #step-header {
      z-index: 10;
      position: relative !important;
      flex: 0 0 auto;
    }

    #step-container {
      flex: 1 1 auto;
      overflow-y: scroll;
    }

    #step-footer {
      width: 100%;
      position: relative !important;
      flex: 0 0 auto;
    }

    #chat {
      position: fixed;
      right: 0;
      transform-origin: bottom right;
      button.prompt {
        transform: translate(-15px, -70px);
      }
      .prompt.v-card {
        transform: translate(-15px, -229px);
      }
    }
  }
}
</style>
